import { BrowserRouter, Route } from 'react-router-dom'
import HelpStatus from './components/HelpStatus'
import Home from './components/Home'
import QuickHelp from './components/QuickHelp'

const App = () => {
    return(
        <BrowserRouter>
            <Route exact path="/" component={Home}/>
            <Route path="/quick-help" component={QuickHelp}/>
            <Route path="/check-help-status" component={HelpStatus}/>
        </BrowserRouter>
    )
}

export default App